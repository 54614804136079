<template>
  <div>
    <div class="info">
      <el-row>
        <el-col>
          <span>科类:</span>
          <span class="info_2">{{ userInfo.is_wenli == 1 ? '文科' : '理科' }}</span>
          <span>分数:</span>
          <span class="info_2">{{ userInfo.score }}分</span>
          <span>位次:&nbsp;</span>
          <span class="info_2">{{ equalScore[0] }}</span>
          <span>我的同位分:</span>
          <span class="info_3">{{ equalScore[1] }}分</span>
          <span class="info_4">/23年</span>
          <span class="info_3">{{ equalScore[2] }}分</span>
          <span class="info_4">/22年</span>
          <span class="info_3">{{ equalScore[3] }}分</span>
          <span class="info_4">/21年</span>
        </el-col>
      </el-row>

    </div>
    <el-divider class="divider_CP"></el-divider>
    <div>
      <span style="font-size: 16px; font-weight: bold;color:#000000">{{ collegeData.name }}</span>
      <span style="margin-left: 25px;color:#75787b">所在地:{{ collegeData.city_name }}</span>
      <span style="margin-left: 25px;color:#75787b">招生代号:{{ collegeData.school_id }}</span>
      <span style="margin-left: 25px;color:#ff6600">录取规则:{{ collegeData.lqgz }}</span>
      <span style="margin-left: 25px;color:#ff6600" v-if="lqgzList">身体受限:{{ lqgzList.stsx == 1 ? '是' : '否' }}</span>
      <span style="margin-left: 25px;color:#ff6600" v-if="lqgzList">单科要求:{{ lqgzList.danke == 1 ? '是' : '否' }}</span>
      <span style="margin-left: 25px;color:#ff6600" v-if="lqgzList">
        <router-link :to="{
            path: '/College',
            query: { cid: lqgzList.id, sid: lqgzList.school_id, degree: lqgzList.degree },
          }" target="_blank">更多</router-link>
      </span>
    </div>
    <el-row style="margin-top: 25px" :gutter="20" class="plan_tab1">
      <el-col :span="12">
        <el-row type="flex" align="middle" style="margin-bottom: 19px">
          <el-col :span="4" class="box-title">
            <el-popover v-model="collegeData.visibles" popper-class="popover-f" placement="bottom" trigger="manual">
              <div class="popover-box">
                <p class="box-title">填报为</p>
                <p v-for="h in len" :key="h" class="box-item" @click="chooseSchool(collegeData, h, word[h - 1])">
                  平行志愿{{ word[h - 1] }}
                </p>
              </div>
              <div v-if="record.id.indexOf(collegeData.select_code) > -1" slot="reference" class="btn schoolBtn"
                @click="changeAlert(collegeData)">
                志愿{{ record.dg[record.id.indexOf(collegeData.select_code)] }}
              </div>
              <div v-else slot="reference" class="btn" @click="changeAlert(collegeData)">
                填报为
              </div>
            </el-popover>
          </el-col>
          <el-col :span="3"> 服从调剂 </el-col>
          <el-col :span="5">
            <el-radio-group v-model="radio" @change="getTiaoji">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-table :data="planData" class="tab_plan" height="325"
          :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
          :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
          <el-table-column label="今年招生计划">
            <el-table-column prop="specialty_id" label="代号" width="49px" align="center">
              <template slot-scope="scope">
                {{ scope.row.specialty_id | interpo }}
              </template>
            </el-table-column>
            <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="color: #ff6600;" v-if="scope.row.specialty_name.indexOf(keyword) > -1 && keyword">{{
            scope.row.specialty_name }}</div>
                <div v-else>{{ scope.row.specialty_name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="beizhu" label="专业详情" show-overflow-tooltip align="center" width="60">
            </el-table-column>
            <el-table-column prop="plan_num" label="招生计划" align="center" width="60">
            </el-table-column>
            <el-table-column prop="tuition" label="学费/年" align="center" width="100"> </el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <div v-if="record.id.indexOf(collegeData.select_code) > -1">
                  <el-button
                    v-if="record.sp[record.id.indexOf(collegeData.select_code)].indexOf(scope.row.specialty_id) > -1"
                    @click="chooseSpecialty(scope.row)" size="mini" type="primary">
                    已选择
                  </el-button>
                  <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
                    填报
                  </el-button>
                </div>
                <div v-else>
                  <el-button @click="chooseSpecialty(scope.row)" size="mini">填报</el-button>
                </div>

              </template>
            </el-table-column>
          </el-table-column>
        </el-table>

        <el-row style="margin-top:20px">
          <el-col :span="3" :offset="7">
            <el-button size="mini" type="primary" plain @click="refersh">
              重置
            </el-button>
          </el-col>
          <el-col :span="3" :offset="3">
            <el-button size="mini" type="primary" plain @click="save()">
              保存
            </el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <MojarOld v-if="equalList" :school_id='collegeData.school_id' :select_code="collegeData.select_code"
          :searchKey='searchKey' />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MojarOld from "./MojarOld";
import { mapState, mapMutations } from "vuex";

export default {
  props: ["collegeData", "len", "changes", 'keyword'],
  components: {
    MojarOld,
  },
  computed: { ...mapState(["userInfo", 'record', 'reportTable', 'batch', 'equalScore']) },
  name: "",
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      planData: [],
      loading: true,
      searchKey: "",
      radio: true,
      equalList: [],
      lqgzList: {
        tllqyz: ''
      }
    };
  },
  created() {
    console.log(this.keyword)
  },
  mounted() {
    this.radio = this.collegeData.tiaoji;
    if (!this.radio) {
      let index = this.record.id.indexOf(this.collegeData.select_code);
      if (index > -1) {
        this.radio = this.reportTable[index].tiaoji;
      }
    }
    this.getPlan();
    this.getSchoolNews();
  },

  methods: {
    ...mapMutations(['setRecord', 'setReportTable']),
    //获取数据
    getPlan() {
      if (this.collegeData.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer/getPlan", {
            select_code: this.collegeData.select_code,
            batch: this.batch,
          })
          .then((res) => {
            this.planData = res;
            this.loading = false;
          });
      }
    },

    //获取录取规则信息
    getSchoolNews() {
      if (this.collegeData.select_code) {
        this.$fecth
          .post("volunteer/getSchoolNews", {
            school_name: this.collegeData.name,
          })
          .then((res) => {
            this.lqgzList = res
          });
      }
    },

    //填报为
    changeAlert(item) {
      item.visibles = !item.visibles;
      this.$forceUpdate();
    },

    //选择院校
    chooseSchool(item, i, num) {
      item.visibles = false;
      item.tiaoji = 1;
      this.radio = 1;

      //判断是否选择过此学校。如果有就删掉
      let ttt = this.record.id.indexOf(item.select_code);
      if (ttt > -1) {
        this.record.dg.splice(ttt, 1);
        this.record.id.splice(ttt, 1);
        this.record.sp.splice(ttt, 1);
      }
      //判断此位置是否填报过。如果有。覆盖
      let index = this.record.dg.indexOf(num);
      if (index > -1) {
        this.record.id[index] = item.select_code;
        this.record.dg[index] = num;
        this.record.sp[index] = [];
      } else {
        //没有新增
        this.record.id.push(item.select_code);
        this.record.dg.push(num);
        this.record.sp.push([]);
      }

      this.setRecord(this.record)

      //判断此院校是否被其他位置已经填报。有就删除
      for (let s of this.reportTable) {
        if (s.select_code === item.select_code) {
          s.name = "暂无";
          s.specialty = [];
          delete s.school_id;
          delete s.city_name;
          delete s.plan_num;
          delete s.min_score;
          delete s.ranking;
          delete s.select_code;
          delete s.score;
          delete s.lqgz;
        }
      }
      this.reportTable[i - 1].name = item.name;
      this.reportTable[i - 1].school_id = item.school_id;
      this.reportTable[i - 1].city_name = item.city_name;
      this.reportTable[i - 1].plan_num = item.plan_num;
      this.reportTable[i - 1].select_code = item.select_code;
      this.reportTable[i - 1].lqgz = item.lqgz;
      this.reportTable[i - 1].tiaoji = 1;
      this.reportTable[i - 1].specialty = [];
      this.setReportTable(this.reportTable)
      this.$emit('Refresh')
      this.$forceUpdate();
    },


    //选择专业
    chooseSpecialty(item) {
      //判断是否选择志愿位置
      let index = this.record.id.indexOf(this.collegeData.select_code);
      if (index > -1) {
        let sp_id = this.record.sp[index].indexOf(item.specialty_id);
        if (sp_id > -1) {
          this.searchKey = "";
          this.record.sp[index].splice(sp_id, 1);
          for (let s of this.reportTable) {
            if (s.select_code == item.select_code) {
              s.specialty.splice(sp_id, 1);
            }
          }
        } else {
          this.searchKey = item.specialty_name;
          //判断是否已经选了5个志愿
          let splen = this.record.sp[index].length;
          if (splen == 5) {
            this.$alert("每个志愿最多填报5个专业！", "提示", {
              confirmButtonText: "确定",
            });
            return false;
          } else {
            for (let s of this.reportTable) {
              if (s.select_code == item.select_code) {
                s.specialty.push({
                  specialty_name: item.specialty_name,
                  plan_num: item.plan_num,
                  tuition: item.tuition,
                  specialty_id: item.specialty_id,
                  remarks: item.remarks,
                });
              }
            }
          }
          this.record.sp[index].push(item.specialty_id);
        }
      } else {
        this.$alert("请先选择院校", "提示", {
          confirmButtonText: "确定",
        }).catch((msg) => {
          console.log(msg);
        });
      }
    },

    //保存

    save() {
      this.setRecord(this.record)
      this.setReportTable(this.reportTable)
      this.$emit('Refresh')
      this.$forceUpdate();
    },

    //重置
    refersh() {
      let index = this.record.id.indexOf(this.collegeData.select_code);
      console.log(this.reportTable);

      if (index > -1) {
        this.record.sp[index] = [];
        this.reportTable[index].specialty = [];
        this.setRecord(this.record)
        this.setReportTable(this.reportTable)
        this.$forceUpdate();
      }
    },

    //调剂
    getTiaoji() {
      this.collegeData.tiaoji = this.radio;
      let index = this.record.id.indexOf(this.collegeData.select_code);
      if (index > -1) {
        this.reportTable[index].tiaoji = this.radio;
        this.setReportTable(this.reportTable)
      }
    },
  },
};
</script>

<style scoped lang='less'>
.btn {
  text-align: center;
  border-radius: 20px;
  border: 1px solid #409eff;
  color: #8c939d;
  width: 68px;
  margin: 10px auto 15px;
  font-size: 12px;
  line-height: 24px;
}

.schoolBtn {
  border-radius: 4px;
  background: #409eff;
  color: white;
}

.info {
  font-size: 14px;
  position: relative;

  span {
    display: inline-block;
  }

  .info_1 {
    margin-left: 15px;
    color: #ff6600;
    margin-right: 30px;
  }

  .info_2 {
    color: #2871fb;
    margin-right: 25px;
  }

  .info_3 {
    color: #ff6600;
    margin-left: 15px;
  }

  .info_4 {
    color: #75787b;
  }
}

//选择院校
.popover-f {
  width: 70px;
  background-color: #3d3d3c;
  color: white;
  min-width: 0;

  .popper__arrow {
    display: none;
  }

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    padding: 10px 0;
    font-size: 14px;
  }
}
</style>

<style lang="less">
.tab_plan {

  .el-table td,
  .el-table th {
    padding: 0px;
  }
}

.divider_CP {
  margin: 10px 0;
}
</style>